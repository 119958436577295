<template>
  <div class="JoinDetail">
    <div class="wrapper">
      <div class="title">{{ jobInfo.position }}</div>
      <div class="date">{{ jobInfo.timeAdd }}</div>
      <div class="baseInfo">
        <span>工作性质：{{ jobInfo.nature }}</span>
        <span>最低学历：{{ jobInfo.education }}</span>
        <span>招聘人数：{{ jobInfo.number }}</span>
        <span>工作地点：{{ jobInfo.cityName }}</span>
        <span>工作经验：{{ jobInfo.experience }}</span>
      </div>
      <div class="post">
        <div class="post-item" v-html="jobInfo.remarks" />
      </div>
      <div class="pagecontrol">
        <div class="right">
          <div class="back-but" @click="backNewsList">返回列表</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JoinDetail',
  data() {
    return {
      jobInfo: {}
    }
  },
  created() {
    this.jobInfo.joinId = this.$route.query.joinId
    console.log(this.jobInfo.joinId, '路由传过来啊传过来')
    this.initData()
  },
  methods: {
    backNewsList() {
      this.$router.push('/baseHome/join')
    },
    initData() {
      this.$http.post('api/Job/GetSingleJob?jobId=' + this.$route.query.joinId, {}).then(res => {
        this.jobInfo = res.data
        console.log(this.jobInfo)
      })
    }
  }
}
</script>

<style lang="scss">
.JoinDetail {
  .title {
    font-size: 28px;
    color: #000;
    font-weight: bolder;
    text-align: center;
  }
  .date {
    text-align: center;
    color: #999;
    font-size: 16px;
    margin-top: 35px;
    margin-bottom: 50px;
  }
  .baseInfo {
    overflow: hidden;
    background: #fff;
    border-radius: 10px;
    padding:30px 0;
    span {
      float: left;
      line-height: 34px;
      width: 550px;
      margin-left: 100px;
      display: block;
      font-size: 15px;
      color: #414141;
    }

  }
  .post {
    padding: 0 100px;
    margin-bottom: 120px;
    .post-item {
      margin-top: 60px;
      line-height: 40px;
      font-size: 16px;
      color: #414141;
      h2 {
        color: #222;
        font-weight: bolder;
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
  }
  .pagecontrol {
      margin-top: 75px;
      margin-bottom: 136px;
      .right {
        .back-but {
          cursor: pointer;
          width: 120px;
          height: 36px;
          margin:0 auto;
          background-color: #0062db;
          color: #fff;
          font-size: 16px;
          text-align: center;
          line-height: 36px;
          transition: all 0.5s;

          &:hover{
            background-color: #3283e7;
          }
        }
      }
    }
}
</style>
